import type * as React from "react";

import { Box, LinkButton, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { BackButton } from "@/components/ui/BackButton/BackButton";
import { path } from "@/routes";
import commonStyles from "@/styles/common.module.css";

const EMAILINPUT_STORAGE_KEY = "security-mfa-email-input";
const MFASETUPTOKEN_STORAGE_KEY = "security-mfa-email-mfasetup-token";
const BACKUPCODE_STORAGE_KEY = "security-mfa-email-backup-code";

const SecurityMfaCompletePage: React.FC = () => {
  const { t } = useTranslation();

  const handleClickRemoveStorage = () => {
    const storageList = [
      EMAILINPUT_STORAGE_KEY,
      MFASETUPTOKEN_STORAGE_KEY,
      BACKUPCODE_STORAGE_KEY,
    ];
    for (const key of storageList) {
      sessionStorage.removeItem(key);
    }
  };

  return (
    <>
      <ArticleTitle
        title={t("security.mfa.complete.title")}
        testId="article-security-mfa-complete"
      >
        <Typography as="p" className={commonStyles.articleDescription}>
          <span>{t("security.mfa.complete.description")}</span>
          <br />
          <span>
            {t("security.mfa.complete.description_backup_code_confirmable")}
          </span>
        </Typography>
      </ArticleTitle>
      <Box
        className={`${commonStyles.articleHeaderInner} ${commonStyles.itemGap16} pt-4 pb-4`}
      >
        <LinkButton
          size="full"
          variant="border"
          data-testid="submit"
          to={path.security.mfa.backupCode}
        >
          {t("security.mfa.complete.button.backup_code")}
        </LinkButton>
        <LinkButton
          to={path.security.mfa.root}
          onClick={handleClickRemoveStorage}
          size="full"
          data-testid="confirm-setting"
        >
          {t("security.mfa.complete.button.confirm_setting")}
        </LinkButton>
        <Box className={commonStyles.itemCenter}>
          <BackButton
            to={path.root}
            testId="button-complete"
            onClick={handleClickRemoveStorage}
            buttonText={t("security.mfa.complete.button.complete")}
          />
        </Box>
      </Box>
    </>
  );
};

export default SecurityMfaCompletePage;
