import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";

import PersonalEmailChangeInputFeature from "@/features/Personal/EmailChange/PersonalEmailChangeInputFeature";
import { Typography } from "nikkei-ui";

import commonStyles from "@/styles/common.module.css";

export const PersonalEmailChangePage = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-email-change-input"
        title={t("personal.edit.email_change.title")}
      >
        <Typography as="p" className={commonStyles.articleDescription}>
          {t("personal.edit.email_change.description")}
        </Typography>
      </ArticleTitle>
      <PersonalEmailChangeInputFeature />
    </>
  );
};

export default PersonalEmailChangePage;
