import { Box, LinkButton, Typography } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { path } from "@/routes";
import commonStyles from "@/styles/common.module.css";

const PersonalAddressCompletePage = (): React.JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box className={`${commonStyles.articleHeader} mb-10`}>
      <Box className="mb-20">
        <ArticleTitle
          testId="article-personal-address-complete"
          title={t("personal.edit.address.complete.title")}
        >
          <Typography as="p" className={commonStyles.articleDescription}>
            {t("personal.edit.address.complete.description")}
          </Typography>
        </ArticleTitle>
      </Box>

      <Box className={commonStyles.articleHeaderInner}>
        <LinkButton to={path.personal.root} data-testid="submit" size="full">
          {t("personal.edit.button.complete")}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default PersonalAddressCompletePage;
