import type * as React from "react";

import { Link, Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalFeature } from "@/features/Personal/PersonalFeature";
import { path } from "@/routes";
import commonStyles from "@/styles/common.module.css";
import { LoginWallProvider } from "nid-common";

import { Layout } from "../../components/ui/Layout";

export const PersonalPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.personal")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.personal")}
          </Typography>
        </>
      }
      description={t("personal.description")}
    >
      <LoginWallProvider>
        <ArticleTitle title={t("personal.title")} testId="article-personal">
          <Typography as="p" className={commonStyles.articleDescription}>
            {t("personal.description")}
          </Typography>
        </ArticleTitle>
        <PersonalFeature />
      </LoginWallProvider>
    </Layout>
  );
};
