import type * as React from "react";

import { Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { SecurityPasskeyCreatedFeature } from "@/features/Security/Passkey/SecurityPasskeyCreatedFeature";
import commonStyles from "@/styles/common.module.css";

export const SecurityPassKeyCreatedPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ArticleTitle
        title={t("security.passkey.created.title")}
        testId="article-security-passkey-created"
      >
        <Typography as="p" className={commonStyles.articleDescription}>
          {t("security.passkey.created.description")}
        </Typography>
      </ArticleTitle>
      <SecurityPasskeyCreatedFeature />
    </>
  );
};

export default SecurityPassKeyCreatedPage;
