import { Box, Link, Typography } from "nikkei-ui";
import type * as React from "react";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { Layout } from "@/components/ui/Layout";
import { path } from "@/routes";
import commonStyles from "@/styles/common.module.css";

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout
      title={t("breadcrumbs.not_found")}
      breadcrumbs={
        <>
          <Link to={path.root} size="14">
            {t("breadcrumbs.top")}
          </Link>
          <Typography as="span" size="14">
            {t("breadcrumbs.not_found")}
          </Typography>
        </>
      }
    >
      <Box className="mb-10">
        <ArticleTitle
          testId="article-notfound"
          title={t("error.not_found.title")}
        />
        <Box className={commonStyles.sectionInner}>
          <Typography>{t("error.not_found.message")}</Typography>
          <Box
            className={`${commonStyles.itemCenter} mt-3`}
            data-testid="notfound"
          >
            <Link href={path.root}>{t("error.not_found.link")}</Link>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
