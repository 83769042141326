import type React from "react";

import { Box } from "nikkei-ui";
import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { PersonalSurveyInputFeature } from "@/features/Personal/Survey/PersonalSurveyInputFeature";
import commonStyles from "@/styles/common.module.css";

export const PersonalSurveyPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ArticleTitle
        testId="article-personal-survey-input"
        title={t("personal.edit.survey.title")}
      />

      <Box
        as="section"
        className={`${commonStyles.section} ${commonStyles.sectionWithoutBackground}`}
      >
        <Box className={commonStyles.sectionInner}>
          <PersonalSurveyInputFeature />
        </Box>
      </Box>
    </>
  );
};
