import type React from "react";

import { Typography } from "nikkei-ui";

import { useTranslation } from "react-i18next";

import ArticleTitle from "@/components/ui/ArticleTitle/ArticleTitle";
import { ServiceContractCancelAlertFeature } from "@/features/Services/Contract/Cancel/ServiceContractAlertFeature";
import { ServiceContractCancelFeature } from "@/features/Services/Contract/Cancel/ServiceContractCancelFeature";
import commonStyles from "@/styles/common.module.css";

export const ServiceContractCancelPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ServiceContractCancelAlertFeature />
      <ArticleTitle
        title={t("services.service_contract_cancel.title")}
        testId="article-service-contract-cancel"
      >
        <Typography as="p" className={commonStyles.articleDescription}>
          {t("services.service_contract_cancel.description")}
        </Typography>
      </ArticleTitle>
      <ServiceContractCancelFeature />
    </>
  );
};

export default ServiceContractCancelPage;
